<template>
  <div id="app">
    <div style="height: 70px;"></div>
    <div class="top"  ref="navigation_bar">
      <div class="Information_bar"></div>
      <ul class="navigation_bar_spare">
        <li>
          <img @click="home" style="cursor:pointer;" src="https://img.youstarplanet.com/h5/logo.svg" alt="">
        </li>
        <li v-show="list_state">
          <img @click="listBtn" style="cursor:pointer;" src="https://img.youstarplanet.com/h5/list.svg" alt="">
        </li>
        <li v-show="!list_state">
          <img @click="listBtn" style="cursor:pointer;" src="https://img.youstarplanet.com/h5/fork.svg" alt="">
        </li>
      </ul>
      <ul class="list_navigation" v-show="list_navigationState">
        <li @click="listNavigationBtn"><router-link to="/Home">主页</router-link></li>
        <li @click="listNavigationBtn"><router-link to="/ServiceCenter">服务中心</router-link></li>
        <li @click="listNavigationBtn"><router-link to="/OurProducts">我们的产品</router-link></li>
        <li @click="listNavigationBtn"><router-link to="/AboutUs">关于我们</router-link></li>
      </ul>
    </div>
    
    <router-view class="content"></router-view>

    <div class="base">
      <ul>
        <li>
          <div>联系我们</div>
          <div><router-link to="/CooperationNegotiate">合作洽谈</router-link></div>
        </li>
        <li>
          <div>法律信息</div>
          <div><router-link to="/ServiceAgreement">服务协议</router-link></div>
          <div><router-link to="/PrivacyPolicy">隐私政策</router-link></div>
        </li>
        <li>
          <div>关注我们</div>
          <div>
            <img style="cursor:pointer;" src="https://img.youstarplanet.com/h5/erweim.png" alt="">
          </div>
        </li>
        <li>
          <div><router-link to="/LegalNotices">法律声明</router-link></div>
          <div><router-link to="/RuleOfSunshine">阳光准则</router-link></div>
        </li>
      </ul>
      <ul>
        <li> © 2021 - 2022 星都时代</li>
        <li>粤公网安备44030602006653号</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default{
  data() {
    return {
      list_state: true,
      list_navigationState: false,
      //监听滚动事件
      scroll:'',
      scrollToped:'',//上一个滚动事件
      scrollTopeds:'',
    }
  },
  created(){
    //判定是手机打开还是pc
    if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      window.location.href = "https://m.youstarplanet.com/#/Home";
      // window.location.href = "http://testh5.youstarplanet.com/#/Home";
      // console.log('移动端');
    } else {
      window.location.href = "https://www.youstarplanet.com/#/Home";
      // window.location.href = "http://test.youstarplanet.com/#/Home";
      // console.log('PC端');
    }
  },
  methods: {
    home(){
      this.$router.push({path:`/Home`})
    },
    //列表导航栏
    listBtn(){
      if (this.list_state) {
        this.list_state = false
        this.list_navigationState = true
      }else{
        this.list_state = true
        this.list_navigationState = false
      }
    },
    listNavigationBtn(){
      this.list_state = true
      this.list_navigationState = false
    },
    //监听滚动条
    handleScroll(){
      this.scrollTopeds = this.scrollToped
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      this.scrollToped = scrollTop
      // console.log(this.scrollTopeds,'过去','===',scrollTop,'当前');
      if (scrollTop >= 86) {
        this.list_state = true
        this.list_navigationState = false
        this.$refs.navigation_bar.style.top = "-70px"//下滑
      }
      if (this.scrollTopeds > scrollTop) {
        this.$refs.navigation_bar.style.top = "0px"//上滑
      }
    }
  },
  mounted() {
    window.addEventListener('scroll',this.handleScroll,true)
  }
}
</script>


<style lang="less">
  *{
    margin: 0;
    padding: 0;
  }
  a, ul, li, div, p{
    color: #101010;
    box-sizing: border-box;
  }
  ul{
    list-style: none;
  }
  a{
    text-decoration: none;
  }
#app {
  .top{
    background-color: #FFFFFF;
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    top: 0px;
    transition: all 0.5s;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.06);
    .Information_bar{
      width: 100%;
      height: 16px;
      background-color: #FFF;
    }
    .navigation_bar_spare{
      display: flex;
      width: 100%;
      height: 54px;
      padding: 0 28px;
      justify-content: space-between;
      align-items: center;
      &>li:nth-child(1){
        &>img{
          width: 110px;
          height: 18px;
        }
      }
    }
    .list_navigation{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: fixed;
      z-index: 1000;
      top: 70px;
      transition: all 0.5s;
      // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
      background-color: #FFFFFF;
      &>li{
        width: 100%;
        border-top: 1px solid #E4E4E4;
        &:nth-child(1){
          border-top: none;
        }
        a{
          display: block;
          height: 52px;
          line-height: 52px;
          text-align: center;
          font-size: 15px;
          font-weight: 400;
          color: #999999;
        }
        .router-link-active{
          color: #000000;
          font-weight: bold;
        }
      }
    }
  }
  .base{
    padding: 26px 15px 28px 15px;
    background-color: #F8F8F8;
    &>ul{
      display: flex;
      flex-direction: column;
      align-items: center;
      &:nth-child(1){
        &>li{
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 44px;
          &:nth-child(3){
            margin-bottom: 50px;
          }
          &:nth-child(4){
            margin-bottom: 19px;
            &>div:nth-child(1){
              font-size: 14px;
              margin-bottom: 14px;
              font-weight: normal;
            }
          }
          &>div{
            font-size: 14px;
            a{
              color: #666666;
            }
            &:nth-child(1){
              font-size: 16px;
              font-weight: bold;
              color: #000000;
              margin-bottom: 14px;
            }
            &:nth-child(3){
              margin-top: 14px;
            }
            // img:nth-child(1){
            //   margin-right: 22px;
            // }
            img{
              width: 120px;
            }
          }
        }
      }
      &:nth-child(2){
        padding-top: 17px;
        border-top: 1px solid #DFDFDF;
        &>li{
          color: #BFBFBF;
          font-size: 12px;
          &:nth-child(1){
            margin-bottom: 13px;
          }
        }
      }
    }
  }
}
</style>
